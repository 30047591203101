/*
  Contains initalization data for the price chart.
*/

// token balance
// const xData1 = [
//   0,
//   5000,
//   5500,
//   6000,
//   6500,
//   7000,
//   7500,
//   8000,
//   8500,
//   9000,
//   9500,
//   10000,
//   10500,
//   11000,
//   11500,
//   12000,
//   12500,
//   13000,
//   13500,
//   14000,
//   14500,
//   15000,
//   15500,
//   16500,
//   17500,
//   18500,
//   19500,
//   20000,
// ]

const xData2 = [
  -100000,
  -95000,
  -90000,
  -85000,
  -80000,
  -75000,
  -70000,
  -65000,
  -60000,
  -55000,
  -50000,
  -45000,
  -40000,
  -35000,
  -30000,
  -25000,
  -20000,
  -15000,
  -10000,
  -5000,
  1,
  5000,
  10000,
  15000,
  20000,
  25000,
  30000,
  35000,
  40000,
  45000,
  50000,
  55000,
  60000,
  65000,
  70000,
  75000,
  80000,
  85000,
  90000,
  95000,
  100000,
]

// BCH/Token balance
const yData1 = [
  33.8338208091532,
  37.3921548056588,
  41.3247220553966,
  45.6708810131837,
  50.4741294986639,
  55.7825400371075,
  61.6492409854016,
  68.1329482585032,
  75.2985529780505,
  83.2177709245199,
  91.9698602928606,
  101.64241493515,
  112.332241029305,
  124.146325947852,
  137.202909023507,
  151.632664928158,
  167.58001150891,
  185.204555170429,
  204.682688269495,
  226.20935450899,
  250,
  275,
  300,
  325,
  350,
  375,
  400,
  425,
  450,
  475,
  500,
  525,
  550,
  575,
  600,
  625,
  650,
  675,
  700,
  725,
  750,
]

// $ / token balance
// const yData2 = [
//   0.135335283236613,
//   0.149568619222635,
//   0.165298888221587,
//   0.182683524052735,
//   0.201896517994655,
//   0.22313016014843,
//   0.246596963941606,
//   0.272531793034013,
//   0.301194211912202,
//   0.33287108369808,
//   0.367879441171442,
//   0.406569659740599,
//   0.449328964117222,
//   0.49658530379141,
//   0.548811636094026,
//   0.606530659712633,
//   0.670320046035639,
//   0.740818220681718,
//   0.818730753077982,
//   0.90483741803596,
//   1,
//   1.1,
//   1.2,
//   1.3,
//   1.4,
//   1.5,
//   1.6,
//   1.7,
//   1.8,
//   1.9,
//   2,
//   2.1,
//   2.2,
//   2.3,
//   2.4,
//   2.5,
//   2.6,
//   2.7,
//   2.8,
//   2.9,
//   3,
// ]

const initChartData = {
  // xData1,
  xData2,
  yData1,
  // yData2,
}

export default initChartData
